.searchJobCard{
  text-align: right;
}
@media only screen and (max-width: 600px){
  .searchJobCard{
    text-align: center;
  }
}
@media only screen and (max-width: 820px){
  .searchJobCard{
    text-align: center;
  }
}

/* header part css */




.centerpoints-header {
  display: flex;

  align-items: center;
}
.headerdetails {
  padding: 2px 35px;
  padding-top: 5px;
  color: #1976d2;

  font-size: small;
  font-family: "Poppins-Regular";
}
.headerdetails :hover {
  text-decoration: underline;
  cursor: pointer;
}

.headericonspace {
  display: flex;
  gap: 10px;
  background-color: rgba(245, 245, 245, 0.9);
}
.iconsforheaders {
  flex-grow: 1;
}
.headericoncenter {
  display: flex;
  justify-content: space-around;
}
.iconsspace {
  padding: 0px 10px;
}
.headerlandingpage {
  position: fixed;
  z-index: 999;
  width: 100%;
}
.reg-btn-header {
  margin-right: 10px !important;
}
.reg-btn-header-contact{
  margin-right: 10px !important;
  border-bottom: 3px solid #ed1167cc !important;
}
.reg-btn-header:hover {
  border-bottom: 3px solid #ed1167cc !important;
}
.login-btn-header:hover {
  border-bottom: 3px solid #ed1167cc !important;
}
.header-link-active {
  border-bottom: 3px solid #ed1167cc !important;
}

.headerlogoimg-footer img {
  width: 45%;
  border-radius: 5px;
  background-color: white;
  padding: 0px 5px;
}
.headerlogoimg img {
  width: 100%;
  border-radius: 5px;
  background-color: white;
  padding: 0px 5px;
}
.headerlogoimg2 img {
  width: 100%;
  border-radius: 5px;
  background-color: white;

}
.login-btn-header-tamil {
  font-weight: 600 !important;
  font-size: 14px !important;
  border-bottom: 3px solid transparent  !important;
}
.login-btn-header-tamil:hover {
  border-bottom: 3px solid #ed1167cc !important;
}
.login-btn-header-tamil-1 {
  font-weight: 600 !important;
  font-size: 14px !important;
  border-bottom: 3px solid #ed1167cc  !important;
}
.login-btn-header-tamil-1:hover {
  border-bottom: 3px solid #ed1167cc !important;
}
.headerlogoimg {
  padding-top: 8px;
  width: 15%;
}
.headerlogoimg2 {
  padding-top: 8px;
  width: 11%;
  padding-left: 10px;
}
.headerbutton {
  flex-grow: 1;
  text-align: end;
}

.headerbutton Button {
  color: #ed1067;
  font-weight: 700;
  font-style: bold;
  background-color: aliceblue;
}

.boxshadowlanding {
  box-shadow: 0px 0px 0px 0px rgba(6, 117, 162, 0.7) !important;
  padding: 0px 10px;
}

/* header part is over */

/* landingpagecontect css */

.herotextimg {
  width: 100%;
  height: 115vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.herotextimg-1 {
  width: 100%;
  height: 55vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nameTitleServiceProvider:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
  transition: all 5s;
}

.herotextimg h3 {
  margin: 0%;
  color: #fff;
  text-align: center;
  font-size: 48px;
  /* font-weight: 700; */
  font-family: Barlow-Bold;
}

.herotextimg p {
  color: #fff;
  width: 80%;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.herotextimg .tamil-p{
  font-size: 15px;
}

.maintextforhero {
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.maintextforhero-1 {
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
}
.maintextforhero span {
  color: #992452;
}

.herotextimg::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 130%;
  height: 100%;
  background: linear-gradient(
      to right,
      rgba(6, 117, 162, 0.8),
      rgba(76, 12, 38, 0.8)
    ),
    url("../../img/heroimg.jpg") center top no-repeat;
  background-size: cover;
  z-index: 0;
  border-radius: 0 0 50% 50%;
  transform: translateX(-50%) rotate(0deg);
}
.herotextimg-1::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 130%;
  height: 100%;
  background: linear-gradient(
      to right,
      rgba(6, 117, 162, 0.8),
      rgba(76, 12, 38, 0.8)
    ),
    url("../../img/heroimg.jpg") center top no-repeat;
  background-size: cover;
  z-index: 0;

  transform: translateX(-50%) rotate(0deg);
}

/* .herotextimg::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 130%;
  height: 96%;
  background: #233a53;
  opacity: 0.3;
  z-index: 0;
  border-radius: 0 0 50% 50%;
  transform: translateX(-50%) translateY(18px) rotate(2deg);
} */

.reg-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

/* search */
.autocom-search {
  border: 1px solid transparent !important;
}
.search-auto {
  display: flex;
  padding: 8px 8px;
  background-color: white;
  border-radius: 50px;
  gap: 20px;
}
.search-part {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  margin-top: 60px;
}

.search-part-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  margin-top: 0;
  padding-bottom: 10px;
}

.mobile-search {
  width: 100% !important;
  max-width: 700px !important;
  background-color: white !important;
  display: flex;
  align-items: end;
  border-radius: 60px !important;
  border: 1px solid transparent !important;
}
.mobile-search:hover {
  border: 1px solid #0675a2 !important;
  background-color: transparent !important;
  color: white !important;
}
.mobile-search-active {
  width: 100% !important;
  max-width: 700px !important;
  background-color: transparent !important;
  border: 1px solid #0675a2 !important;
  display: flex;
  align-items: center;
  border-radius: 60px !important;  
}
.mobile-search p {
  color: #0675a2;
  margin: 0%;
  padding: 0px 10px;
}
.mobile-search-a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 50vh;
  background-color: rgba(19, 18, 18, 0.1);
}
.mobile-close-btn {
  position: absolute;
  top: 0;
  right: 25px;
  margin-left: 50px;
  transform: translate(55%, 0%);
}
/* .phone-view{
  background-color: #16222e;
} */
.autocom-search-first::before {
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(to top, transparent, #0675a2, transparent);
  opacity: 0.5;
}

/* latestjobs */

.hidendiv{
  position: relative;
  animation: slideme 1.3s ease-in;
}
.hidendiv-1 {
  position: relative;
  animation: slideme 1.3s ease-in;
}
@media only screen and (max-width: 600px){
  .hidendiv,.hidendiv-1{
    display: none;
  }
}
@keyframes slideme {
  0%{
    transform:  translateY(600px);
  }
  100%{
    transform: translateY(-300px);
  }
}

.hidendiv .close{
  position: absolute;
  z-index: 999;
  color: white;
  background-color: #e2e7ec;
  border-radius: 50%;
  top: 0%;
  right: 0%;
}
.hidendiv-2{
  display: none;
}
.hidendiv img{
  width: 100%;
}
.hidendiv-1 img{
  width: 100%;
}

.close-1{
  position: absolute;
  z-index: 999;
  color: white;
  background-color: #e2e7ec;
  border-radius: 50%;
  top: 0%;
  right: 0%;
}
.videopopup{
  width: 670px;
  height: 500px;
}
.maptamilnadu{
  background-image: url(../../img/tamilnadumap.png);
  background-position: center;
  background-size: 400px;
  background-repeat: no-repeat;
}

.popupboxcenter-main {
  width: 975px;
  height: 535px !important;
}
.popupboxcenterfacebook{
  width: 450px;
  height: 450px;
}
.popupboxcenterfacebook img{
  width: 100%;
}

.distirick-box{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px !important;
  margin: 0px 20px;
  cursor: pointer;
}
.scroll-districk::-webkit-scrollbar{
  width: 5px;
}
.scroll-districk::-webkit-scrollbar-thumb {
  background: #0077b5; 
  border-radius: 10px;
  
}

.distirick-box:hover .districk-img img{
  transform: scale(1.2);
}


.districk-img{
  display: grid;
  place-items:center ;
  padding-bottom: 5px;
}

.districk .MuiOutlinedInput-root {
  border-radius: 0% !important;
}

.districk-img img{
 width: 50%;
 border-radius: 50%;
 transition: 0.3s;
}
.distirick-box:hover .hoverdistrick{
  color: #ce2f6e !important;
  transition: 0.3s;
  cursor: pointer;
}

.listdistrick {
  font-family: "Poppins-Regular";
}
.hoverdistrick {
  font-weight: 400;
}
.hoverdistrick:hover {
  color: #ce2f6e !important;
  transition: 0.3s;
  cursor: pointer;
}
.headerfordistrick {
  font-family: "Poppins-SemiBold";
  color: #0077b5;
}
.popupboxcenter {
  width: 670px;
  animation: slideme 0.5s forwards;
  height: 0px;
  height:500px !important;
  overflow: hidden;
}.popupboxcenter a{
  width: 100%;
}
.popupboxcenter img {
  width: 100%;
  height: 100%;
}

@keyframes slideme {
  0%{
    height: 0px;
  }
  100%{
    height: 500px;
  }
}


/* hiddenadd */

.imgforlatestjobs{
  width: 50%;
}


.headerforlatestjob {
  margin-bottom: 5px;
  font-size: 18px;
  text-overflow: ellipsis;
  font-family: Barlow-Bold;
  font-weight: 700;
  color: rgba(6, 117, 162, 0.8);
  overflow: hidden;
}
.headerforlatestjob-d {
  margin-bottom: 5px;
  font-size: 18px;
  text-overflow: ellipsis;
  font-family: Barlow-Bold;
  font-weight: 700;
  color: rgba(6, 117, 162, 0.8);
  overflow: hidden;
  width: 140px;
}
.headerforlatestjob-1{
  margin-bottom: 5px;
  font-size: 18px;
  text-overflow: ellipsis;
  font-family: Barlow-Bold;
  font-weight: 700;
  overflow: hidden;
  width: 200px;
  color: rgb(237 30 112 / 80%);;
}
.latestjobsbox {
  background-color: rgba(6, 117, 162, 0.1);
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
  border: 3px solid white ;
  transition: 0.3s;
  height: 80px;
}

.latestjobsbox:hover{
  border: 3px solid #16222e ;
}

.latestjobsbox-1 {
  background-color: rgba(6, 117, 162, 0.1);
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
  border: 3px solid white ;
  transition: 0.3s;
  height: 80px;
}
.latestjobsbox-1:hover{
  border: 3px solid rgb(237 30 112 / 50%) ;
}


.latestcompanyname {
  margin-top: 5px;
  font-weight: 400;
  color: #363535;
  font-family: Barlow-Medium;
  line-height: 22px;
}
.latestcompanyname-1 {
  border-radius: 5px;
  padding: 0px 5px;
  margin-top: 5px;
  font-weight: 400;
  color: white;
  font-family: Barlow-Medium;
  
  background-color: #344c63;
  overflow: hidden;
}

.section-title {
  padding: 15px;
  text-align: center;
}

.section-title h2 {
  font-size: 20px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ce2f6e;
  font-family: "Poppins", sans-serif;
}
.section-title .section-title-p-tag{
  line-height: 27px;
}
.section-title .section-title-p-tag::after,.section-title .section-title-p-tag::before{
  margin: 4px 5px;
}
.section-title h2::after {
  content: "";
  width: 60px;
  height: 1px;
  display: inline-block;
  background: #0675a2;
  margin: 4px 10px;
}
.section-title h2::before {
  content: "";
  width: 60px;
  height: 1px;
  display: inline-block;
  background: #0675a2;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #0675a2;
}

/* count */

.countbox {
 text-align: center;
 display: grid;
 grid-template-columns: 25% 25% 25% 25% ;
}

.countbox-a {
  text-align: center;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33% ;
 }

.countnumber {
  padding: 10px 0px;
}

.count-box-contect h3 {
  font-size: 48px;
  display: block;
  color: #0675a2;
  font-weight: 700;
  margin: 0;
}
.count-box-h3{
  font-size: 45px;
  display: block;
  color: #0675a2;
  font-weight: 700;
  margin: 0;
  margin-bottom: -10px;
}
.countboxbox {
  margin-top: 8px;
}
.countboxbox p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #37423b;
  text-transform: uppercase;
}
/* localserachbtn */

.serachforlocal {
  background-color: rgba(0, 116, 179, 0.4) !important;
  justify-content: space-between;
}

/* yutube */
.youtube:hover .youtubeicon {
  background-color: white;
  transition: 0.3s;
}
/* localjobservice */

.Localjobservice {
  padding-bottom: 40px;
}

.sidetitle {
  padding-top: 0px !important;
}
.Localjobservicebtn {
  padding: 30px;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.Localpostjob {
  border-color: #0675a2;
  font-size: 20px;
  text-transform: uppercase;
  color: #0675a2;
  font-family: Bralow-Bold;
  font-weight: 700;
  padding-top: 4px;
  padding: 2px 30px;
  padding-bottom: 20px;
  border-radius: 10px;
  transition: 0.3s;
}

.Localpostjob:hover {
  border-color: #ed1e70;
  box-shadow: 0px 0px 8px 2px rgba(237, 16, 103, 0.2);
  color: #ed1e70;
  cursor: pointer;
}
.Localpostjob:hover .Localjobicons {
  color: #0675a2 !important;
  border-color: #0675a2;
}

.Localjobservicebtnname {
  border-color: #0675a2;
  font-size: 20px;
  text-transform: uppercase;
  color: #0675a2;
  font-family: Bralow-Bold;
  font-weight: 700;
  padding-top: 4px;
  padding: 2px 30px;
  padding-bottom: 20px;
  border-radius: 10px;
  transition: 0.3s;
}

.Localjobservicebtnname:hover {
  border-color: #ed1e70;
  box-shadow: 0px 0px 8px 2px rgba(237, 16, 103, 0.2);
  color: #ed1e70;
  cursor: pointer;
}
.Localjobservicebtnname:hover .Localjobicons {
  color: #0675a2 !important;
  border-color: #0675a2;
}
/* about us  */

.sidetitle-for-landingpage {
  padding: 10px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 10px;
}

.sidetitle-for-landingpage h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #0675a2;
}
.sidetitle-for-landingpage h2::first-letter {
  color: #ed1067;
}

.sidetitle-for-landingpage h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #ed1067;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.sidetitle-for-landingpage-1 h3 {
  font-size: 30px;
  padding: 5px;
  border-right: 3px solid;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 5s, cursor 0.4s step-end alternate;
  animation-iteration-count: infinite;
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
}

.aboutus-for-landingpage-downpara1 {
  text-align: center;
  margin-bottom: 0;
  color: #0675a2;
  font-family: "Barlow-Medium";
  font-size: 25px;
}
/* .aboutus-for-landingpage-downpara1{
  color: #992452;
} */
.aboutus-for-landingpage-downpara2 {
  margin-top: 20px;
  font-size: 17px;
  color: #6f6f6f;
}
.aboutus-for-landingpage,.about-facebook-adds-img,.features-for-landingpage{
  position: relative;
}
.about-facebook-adds-img .close{
  position: absolute;
    z-index: 999;
    color: white;
    background-color: #e2e7ec;
    border-radius: 50%;
    top: 0%;
    right: 0%;
}
.about-facebook-adds{
  width: 450px;
  position: absolute;
  z-index: 9;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-facebook-adds img{
  width: 100%;
}
.aboutscontect-for-landingpage {
  display: grid;
  grid-template-columns: 50% 50%;
}

.aboutcontext-1-box {
  padding: 10px 20px;
}
.aboutcontext-1-box img {
  width: 100%;
  border-radius: 10px;
}
.aboutcontext-1-box h3 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: Barlow-Bold;
  margin-top: 2px;
  color: rgba(6, 117, 162, 0.8);
}
.aboutcontext-1-box-pcontect {
  margin-top: 7px;
  font-style: italic !important;
}

.listpart-in-abouts {
  padding: 0px 0px;
  padding-top: 14px;
}
.listtype-box {
  display: flex;
  gap: 10px;
}

.listtype-box div:nth-child(2) {
  flex-grow: 1;
}
.listtype-box div:nth-child(2) p {
  margin-top: 7px;
  margin-bottom: 3px;
  font-weight: 400;
  color: #363535;
  font-family: Barlow-Medium;
  font-size: 17px;
  line-height: 22px;
}
.aboutus-video-img {
  margin-top: 20px;
  position: relative;
}

.vidion-of-landingpage {
  font-weight: 400;
  color: #363535;
  font-family: Barlow-Medium;
  font-size: 17px;
}
.dt-of-landingpage {
  font-weight: 500;
  color: #0675a2;
  font-family: Barlow-Bold;
  font-size: 20px;
  margin-bottom: 13px;
}
.dt-of-landingpage span {
  color: #ed1067;
}

.aboutcontext-contect-box p {
  font-family: Barlow-Medium;
  font-size: 16px;
}

.vission-content h4 {
  margin-bottom: 5px;
  font-size: 18px;
  font-family: Barlow-Bold;
  font-weight: 700;
  color: rgba(6, 117, 162, 0.8);
}
.vission-content p {
  margin-left: 30px;
  margin-top: 5px;
  font-weight: 400;
  color: #363535;
  font-family: Barlow-Medium;
  line-height: 22px;
}
/* count of memeber */

.count {
  background: linear-gradient(rgba(1, 1, 8, 0.9), rgba(0, 0, 0, 0.8)),
    url(../../img/aboutus-video-img.png) fixed center center;
  background-size: cover;
  padding: 40px 10px;
  color: #fff;
}
.four-count {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.count-box p {
  margin: 3px;
  margin-top: 20px;
  font-family: Barlow-Bold;
  font-size: 23px;
}
.count-box-contect {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.count-box {
  padding: 14px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* mission part */

.missionbox-of-landing {
  padding: 20px 30px;
}
.mission-for-landingpage {
  margin-top: 30px;
  padding: 10px 0px;
}
.mission-box {
  display: grid;
  grid-template-columns: 60% 30%;
  justify-content: center;
  gap: 20px;
  padding: 50px 40px;
  background-color: rgba(6, 117, 162, 0.1);
  border-radius: 20px;
}

.sideof-mission1 p {
  font-weight: 500;
  font-size: 23px;
  color: #363535;
  font-family: Barlow-Medium;
}
.sideof-mission1 span {
  color: #ed1067;
}
.sideof-mission1 h3 {
  margin-bottom: 3px;
  font-size: 38px;
  font-weight: 700;
  color: rgba(6, 117, 162, 0.8);
}
.sideof-mission1 Button:hover {
  background-color: #ed1067;
  color: white;
  border-color: #ed1067;
}

.sideof-mission img {
  width: 100%;
  border-radius: 20px;
}
.sideof-mission1 {
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding-top: 40px;
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/* features part */

.features-list {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
.features-list div {
  position: relative;
  padding: 15px;
  width: 300px;
  text-align: center;
}
.features-list h4 {
  font-family: Barlow-Medium;
  color: #ed1067;
  font-weight: 400;
}
.features-list div::after {
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(to top, transparent, #0675a2, transparent);
  opacity: 0.5;
}
.features-list div::before {
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to top, transparent, #0675a2, transparent);
  opacity: 0.5;
}
.features-of-list {
  padding: 0px 20px;
  padding-top: 50px;
  color: #fff;
}
.background-for-features {
  background: linear-gradient(rgba(1, 1, 8, 0.9), rgba(0, 0, 0, 0.8)),
    url(../../img/aboutus-img.jpg) fixed center center;
  background-size: cover;
  padding-top: 10px;
  padding-bottom: 50px;
}
.features-list-button {
  padding-top: 50px;
  text-align: center;
}

.features-list-icon {
  font-size: 30px !important;
  background-color: rgba(255, 255, 255, 0.116);
  color: rgb(237, 16, 103);
  padding: 10px;
  border-radius: 50%;
}
.features-for-landingpage {
  margin-top: 30px;
}
/* last contect */

.Lastcontect-for-landingpage {
  margin-top: 30px;
}
.Lastcontect-frame {
  padding: 30px 0px;
}
.lastcontect-split {
  display: flex;
  gap: 30px;
}
.lastcontect-split .img-contect {
  padding: 20px;
  width: 500px;
}
.img-contect img {
  width: 100%;
  border-radius: 10px;
  transition: 0.3s;
}
.img-contect:hover img {
  transform: scale(1.1);
}
.img-contect:hover .lastcontect-landing {
  box-shadow: 0px 0px 8px 2px rgb(6, 117, 162, 0.8);
}
.img-contect:hover .lastcontect-landing-tamil {
  box-shadow: 0px 0px 8px 2px rgb(6, 117, 162, 0.8);
}
.img-contect:hover .lastcontect-landing-english {
  box-shadow: 0px 0px 8px 2px rgb(6, 117, 162, 0.8);
}
.img-contect {
  position: relative;
}
.img-overflow {
  overflow: hidden;
  border-radius: 10px;
}
.img-contect .lastcontect-landing {
  position: absolute;
  top: 45%;
  left: 13%;
  transform: translate(0%, 50%);
  background-color: rgb(255, 255, 255, 0.8);
  padding: 15px 20px;
  width: 228px;
  height: 200px;
  font-family: Barlow-Medium;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  color: #363535;
  transition: 0.5s;
}
.img-contect .lastcontect-landing-tamil {
  position: absolute;
  top: 45%;
  left: 13%;
  transform: translate(0%, 40%);
  background-color: rgb(255, 255, 255, 0.8);
  padding: 15px 20px;
  width: 228px;
  height: 220px;
  font-size: small;
  font-family: Barlow-Medium;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  color: #363535;
  transition: 0.5s;
}
.img-contect .lastcontect-landing-english {
  position: absolute;
  top: 45%;
  left: 13%;
  transform: translate(0%, 40%);
  background-color: rgb(255, 255, 255, 0.8);
  padding: 15px 20px;
  width: 228px;
  height: 220px;
  font-size: 11px;
  font-family: Barlow-Medium;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  color: #363535;
  transition: 0.5s;
}
/* footer */

.footer-landing {
  margin-top: 70px;
  background-color: #065170;
  color: #fff;
  position: relative;
}

.footer-contect {
  text-align: center;
}
.footer-contect h3 {
  font-size: 36px;
  font-weight: 700;
  color: white;
  position: relative;
  font-family: Barlow-Bold;
  padding: 0px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin: 0;
}
.footer-contect {
  padding: 20px 10px;
  padding-bottom: 0px;
}
.footer-contect p {
  font-family: Barlow-Medium;
}
.footer-icon {
  display: flex;
  gap: 20px;
}
.footer-landing-icon {
  display: flex;
  justify-content: flex-start;
  padding: 15px 0px;
  padding-bottom: 5px;
}
.footer-icon-hover-1:hover {
  background-color: #1877f2;
  color: #fff;
}

.footer-icon-hover-2:hover {
  background-color: #ff0000;
  color: #fff;
}
.footer-icon-hover-3:hover {
  background-color: #838586 !important;
  color: #fff !important;
}
.footer-icon-hover-4:hover {
  background-color: #ed1067;
  color: #fff;
}
.footer-icon-hover-5:hover {
  background-color: #0077b5;
  color: #fff;
}
.footer-copyright {
  text-align: center;
  padding: 12px 10px;
  font-family: Barlow-Bold;
}

.footersidecontect {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  padding: 10px;
  width: 90%;
}

.footer-contectbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerlogo {
  padding: 10px;
}
.footerlogo p {
  margin: 5px 0px;
  font-family: "Poppins-SemiBold";
  font-weight: 100;
  font-size: small;
}

.footerlinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40%;
  text-align: left;
}

.footerlinks text {
  font-weight: 700;
  display: flex;
  transition: 0.3s;
}
.footerlinks text:hover {
  text-decoration: underline;
  color: #1877f2;
  cursor: pointer;
}
.linkcolor {
  text-transform: uppercase;
}
.linkcolor:hover {
  color: #1877f2 !important;
}

.footerlinks-1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  text-align: left;
}

.footerlinks-1 text {
  font-weight: 700;
  display: flex;
}

.footercenterlinks {
  display: flex;

  flex-direction: column;
  align-items: center;
}
.footercenterlinks h5 {
  margin: 15px 5px;
  font-size: 30px;
  font-weight: 700;
}

.connectwithus {
  font-weight: 700;
  padding: 10px 10px;
  padding-bottom: 0px;
  font-size: larger;
  text-align: left;
  font-family: "Poppins-SemiBold";
}

/* contactus page */
.Contactuspage-center {
  padding: 20px;
  text-align: center;
}
.Contactuspage-center h2 {
  font-size: 66px;
  font-weight: 500;
  color: #fff;
  font-family: "Barlow-Bold";
  margin: 2px;
}

.contactusbackpage {
  padding: 20px;
  background-color: #e5f0f5;
}
.contactusbackpagefonts {
  font-family: "Barlow-Bold";
  font-weight: 500;
  color: #4278cc;
}
.backbtn:hover {
  text-decoration: underline;
  cursor: pointer;
}
.contactpage-map {
  padding: 20px 40px;
}
.contactusform {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 30px;
}
.contactusformdetails {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
  width: 80%;
  display: grid;
  grid-template-columns: 40% 60%;
  border-radius: 10px;
}
.contacticonbox {
  display: flex;

  align-items: center;
  border-radius: 10px;
  gap: 10px;
  margin: 20px 15px;
  box-shadow: 0px 0px 8px 2px rgb(22, 88, 129);
  padding: 10px;
  background-color: rgba(26, 113, 168, 0.9);
  color: white;
}

.contacticonbox h4,
.contacticonbox p {
  margin: 5px;
}
.detailscontact {
  padding: 15px;
  border-radius: 10px 0px 0px 10px;
  background-color: rgba(19, 92, 130, 1);
}
.inputsforcontactdetails {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.backsideofcenterpace{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: white;
  z-index: 99;
  
}
.backsideofcenterpace{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: .5s ease;
  background-color: white;
  z-index: 99;
  
}
.backsideofcenterpace-text{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.centerpace {
  position: relative;
  width: 100%;
}
.spacet0p {
  display: flex;
  gap: 10px;

  padding-top: 40px;
}
.space-1 {
  padding: 25px 0px;
  text-align: center;
}
.space-3 {
  padding: 30px 0px;
  text-align: center;
}
.spaced-1-1 {
  padding-top: 25px;
}

/* center advertisement part  */

.advertismentcenterbanner-part{
  margin-top: 30px;
}

.banneradvertisment{
  width: 1092px;
  height: 275px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  position: relative;
}
.banneradvertisment img {
  width: 100%;
  border-radius: 15px;
}

.sliding-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  opacity: 0;
}

.sliding-image.active {
  transform: translateX(0);
  opacity: 1;
}




.card-advertisment-part{
  width: 250px;
  height: 250px;
  border-radius: 20px;
  border: 1px solid #0077b5;
  margin-right: 15px;
  overflow: hidden;
}

.card-advertisment{
  margin-top: 30px;
}
.card-advertisment-part img {
  width: 100%;
}

.top-advertisement {
  height: 90px;
  width: 0px;
  z-index: 11111;
  background-color: white;
  position: absolute;
  top: 14%;
  left: 32%;
  transform: translate(-49%, -50%);
  animation: topbanner 2s forwards;
}
.top-advertisement img {
  width: 100%;
}
@keyframes topbanner {
  0%{
    width: 0px;
  }
  100%{
    width: 565px;
  }
}

.bottom-advertisement{
  position: absolute;
  height: 0px;
  width: 800px;
  z-index: 11111;
  background-color: white;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  animation: bottombanner 1s forwards;
}

.bottom-advertisement img{
  width: 100%;
}

@keyframes bottombanner {
  0%{
    height: 0px;
  }
  100%{
    height: 120px;
  }
}


.dd-advetisement img{
   height: 350px;
   width: 350px;
}

.advetisement-social-mediapx{
  display: grid;
  grid-template-columns: 50% 50%;
  place-items: center;
}
.img-ista img{
 width: 100%;
}
@media only screen and (max-width: 600px) {
  .serachforlocal {
    display: flex;
    flex-direction: column !important;
    border-radius: 10px !important  ;
  }
  .autocom-search-first {
    border: 1px solid rgb(255, 252, 252) !important;
    border-radius: 10px !important;
  }
  .footerlogo,
  .connectwithus {
    text-align: center;
  }
  .footer-landing-icon {
    justify-content: center;
  }
  .footerlinks-1 {
    width: 80%;
  }
  .footersidecontect {
    display: grid;
    grid-template-columns: 100%;
    padding: 10px;
    width: 90%;
  }

  .contactusformdetails {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
  }
  .headericon {
    display: none !important;
  }
  .login-btn-header-tamil {
    font-weight: 600 !important;
    font-size: 10px !important;
    border-bottom: 3px solid transparent !important;
  }
  .login-btn-header-tamil:hover {
    font-weight: 600 !important;
    font-size: 10px !important;
    border-bottom: 3px solid #ed1e70 !important;
  }
  .fourtypes-vv {
    flex-wrap: wrap;
  }
  .herotextimg {
    height: 100vh;
  }
  .herotextimg h3 {
    font-size: 28px;
    text-align: center;
  }
  .herotextimg p {
    font-size: 17px;
  }
  .herotextimg .tamil-p {
    display: none !important;
  }
  .buttonregforvv {
    width: 130px;
    height: 130px;
    padding: 5px 5px;
    font-size: 13px;
  }
  .footer-contect h3 {
    font-size: 18px;
  }
  .footer-contect p {
    font-size: 18px;
  }
  .footer-copyright p {
    font-size: 18px;
  }
  .buttonregforvv-1 {
    padding: 5px 5px;
    font-size: 13px;
    width: 130px;
    height: 130px;
  }
  .headerlogoimg {
    width: 90%;
  }
  .headerlogoimg2 {
    width: 90%;
  }
  .headerlogoimg2 img {
    width: 30%;
  }

  .headerlogoimg img {
    width: 60%;
  }
  .iconforcontect {
    font-size: 15px !important;
  }
  .Lastcontect-for-landingpage {
    display: none;
  }
  .aboutscontect-for-landingpage {
    display: grid;
    grid-template-columns: 100%;
  }
  .missionbox-of-landing {
    padding: 0%;
  }
  .sideof-mission1 p {
    font-size: 18px;
  }
  .sideof-mission1 div {
    text-align: center;
  }
  .mission-box {
    display: grid;
    grid-template-columns: 100%;
    padding: 3%;
  }
  .play-btn {
    width: 50px;
    height: 50px;
    transform: translate(50%, 45%);
  }

  .dailogbox-comming-soon {
    padding: 5px 17px;
  }
  .dailog-box-btn {
    padding: 3px 0px;
    padding-top: 5px;
  }
  .dailogbox-comming-soon div:nth-child(1) {
    text-align: center;
  }
  .coming-soon-box p {
    font-size: 19px;
    color: #ed1067;
  }
  .listpart-in-abouts {
    display: none;
  }
  .aboutcontext-1-box-pcontect {
    display: none;
  }
  .aboutcontext-contect-box p {
    display: none;
  }
  .sideof-mission img {
    display: none;
  }
  .features-for-landingpage {
    display: none;
  }
  .count-box-h3{
    font-size: 28px;
    margin-bottom: 0;
  }
  .four-count {
    flex-direction: column;
  }
  .count-box p {
    font-size: 18px;
    text-align: center;
  }
  .reg-btn-header,.reg-btn-header-contact {
    display: none !important;
  }
  .btn-reg {
    padding: 8px 13px !important;
  }
  .search-part {
    display: none;
  }
  .contactusform {
    padding: 40px 15px;
  }
  .section-title h2::after,.section-title h2::before{
     width: 20px;
  }
  .banneradvertisment{
    height: 110px;
  }
  .videopopup{
    width: 361px !important;
    height: 273px !important;
  }
  .popupboxcenter{
    width: 360px !important;
    height: 270px!important ;
  }
  .popupboxcenterfacebook{
    width: 300px !important;
    height: 300px !important;
  }
  .about-facebook-adds{
    width: 300px !important;
  }
  .sidetitle-for-landingpage {
    padding: 15px 10px 10px;
  }
  .top-advertisement{
    display: none !important;
  }
  .bottom-advertisement{
    display: none !important;
  }
  .advetisement-social-mediapx{
     grid-template-columns: 100%;
  }
}

@media only screen and (max-width: 894px) and (min-width: 600px) {
  .banneradvertisment{
    height: 130px;
  }
  
  
  .contactusformdetails {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
  }
  .more-icon {
    display: none !important;
  }
  .headerlogoimg img {
    width: 100%;
  }
  .headerlogoimg2 img {
    width: 100%;
  }
  .search-part {
    display: none;
  }
  .top-advertisement{
    left: 50%;
  }
  .bottom-advertisement{
   width: 80%;
   background-color: transparent;
  }
}

@media only screen and (max-width: 1024px) {
  .autocom-search-first::before {
    content: "";
    height: 100%;
    width: 0px;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(to top, transparent, #0675a2, transparent);
    opacity: 0.5;
  }
  .img-contect .lastcontect-landing-tamil {
    display: none;
  }

  .img-contect {
    display: none;
  }

}
@media only screen and (max-width: 1180px) {
  .herotextimg {
    width: 100%;
    height: 100vh;
  }
 
}
@media only screen and (max-width :1280px) {
  .videopopup{
    width: 668px;
    height: 500px;
  }
}
@media only screen and (max-width: 320px){
  .herotextimg p{
    display: none;
  }
  .herotextimg h3{
       font-size: 20px;
       padding: 10px;
  }
  .btn-reg{
    padding: 3px 5px !important;
  }
  .btn-reg text{
    font-size: 10px;
    padding-top: 0 !important;
  }
  /* .login-btn-header{
    display: none !important;
  } */
}

@media only screen and (max-width: 425px) and (min-width: 375px){
  .herotextimg p{
    display: none;
  }
  .countbox{
    grid-template-columns: 100%;
  }
  .countbox-a{
    grid-template-columns: 100%;
  }
}