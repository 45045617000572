html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: clip;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("./fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("./fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Thin";
  src: url("./fonts/Poppins-Thin.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("./fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("./fonts/Poppins-Black.ttf");
}
@font-face {
  font-family: "Barlow-Medium";
  src: url("./fonts/Barlow-Medium.ttf");
}
@font-face {
  font-family: "Barlow-SemiBold";
  src: url("./fonts/Barlow-SemiBold.ttf");
}
@font-face {
  font-family: "Barlow-Bold";
  src: url("./fonts/Barlow-Bold.ttf");
}
@font-face {
  font-family: "Barlow-Black";
  src: url("./fonts/Barlow-Black.ttf");
}
@font-face {
  font-family: "Barlow-Regular";
  src: url("./fonts/Barlow-Regular.ttf");
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}