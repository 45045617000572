

  .herotextimg-2::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 130%;
    height: 100%;
    background: linear-gradient(
        to right,
        rgba(6, 117, 162, 0.8),
        rgba(76, 12, 38, 0.8)
      ),
      url("../../img/heroimg.jpg") center top no-repeat;
    background-size: cover;
    z-index: 0;
  
    transform: translateX(-50%) rotate(0deg);
  }

  .maintextforhero-2 {
    position: absolute;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;
  }

  .TermsAndCondition-center {
    text-align: center;
  }

 

  .TermsAndConditionBackpage {
    padding: 20px;
    background-color: #e5f0f5;
  }
  .TermsAndConditionBackpagefonts {
    font-family: "Barlow-Bold";
    font-weight: 500;
    color: #4278cc;
  }

  .Termsbackbtn:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .legalFooter:hover{
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (min-width: 250px) {
    .herotextimg-2 {
        width: 100%;
        height: 55vh;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .TermsAndCondition-center h2 {
        font-size: 32px;
        font-weight: 500;
        color: #fff;
        font-family: "Barlow-Bold";
        margin-top: 3rem;
      }
    
  }
  
  @media only screen and (min-width: 300px) {
    
  }
  
  @media only screen and (min-width: 400px) {
    
  }
  
  
  @media only screen and (min-width: 600px) {
    
  }
  
  
  @media only screen and (min-width: 768px) {

    .herotextimg-2 {
        width: 100%;
        height: 55vh;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .TermsAndCondition-center h2 {
        font-size: 48px;
        font-weight: 500;
        color: #fff;
        font-family: "Barlow-Bold";
        margin-top: 6rem;
      }
    
  }
  
  
  @media only screen and (min-width: 912px) {}
  
  
  @media only screen and (min-width: 1200px) {

    .herotextimg-2 {
        width: 100%;
        height: 55vh;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .TermsAndCondition-center h2 {
        font-size: 48px;
        font-weight: 500;
        color: #fff;
        font-family: "Barlow-Bold";
        margin-top: 8rem;
      }
    
  }